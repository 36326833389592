// src/pages/preview.js

import * as React from "react"
import { withPreviewResolver } from "gatsby-source-prismic"

import linkResolver from "../prismic/linkResolver"

import Layout from "../components/Layout/Layout"

const Preview = ({ isPreview, location }) => {
  if (isPreview === false) return "Not a preview!"
  return (
    <Layout location={location}>
      <p>Loading...</p>
    </Layout>
  )
}

export default withPreviewResolver(Preview, {
  repositoryName: "ratelockca",
  linkResolver
})
