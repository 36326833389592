const linkResolver =
  ({ node, key, value } = {}) =>
  (doc) => {
    switch (doc.type) {
      case "blog_post":
        return `/blog/id/${doc.uid}`
      case "service":
        return `/service/${doc.uid}`
      case "form":
        return `/form/${doc.uid}`
      default:
        return "/"
    }
  }

module.exports = linkResolver
